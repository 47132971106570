import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import Loading from '../../../../components/Loading';

import Fechar from '../../../../components/Fechar';
import NotasGrid from './components/NotasGrid';
import { TextField } from '@mui/material';
import { useState } from 'react';

type FormOpenEntregasProps = {
  cancelar: Function;
};

const FormOpenEntrega = ({ cancelar }: FormOpenEntregasProps) => {
  const [observacao, setObservacao] = useState('');
  const entrega = useSelector(
    (state: RootState) => state.formOpenEntrega.data.entrega
  );

  if (entrega === null) {
    return <Loading />;
  }

  return (
    <div className='w-full h-full bg-white p-5 flex flex-col gap-5 overflow-hidden'>
      <Fechar
        titulo={`${entrega.cliente.nome} - ${entrega.cidade.nome}`}
        onClick={() => cancelar()}
      />
      <Divider />
      <div className='w-full'>
        <TextField
          multiline
          rows={2}
          required
          label='Observação'
          id='observacao'
          name='observacao'
          fullWidth
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
          helperText='Ao remover uma nota da entrega, será gerado um registro de protocolo com a observação informada.'
        />
      </div>
      <NotasGrid
        notas={entrega.notas}
        cancelar={() => cancelar()}
        observacao={observacao}
      />
    </div>
  );
};
export default FormOpenEntrega;
