import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotaByOcorrencia } from '../../../../../services/NotaService';
import * as EntregaService from '../../../../../services/EntregaService';
import { Nota } from '../../../../../data/types/Nota';
import { RootState } from '../../../../../state/store';
import { fetchEntregasData } from '../../../state/entregasFetch';
import {
  closeCreatePanel,
  resetSelectedNotas,
  saving,
} from './formCreateEntregasAction';
import { openSnackbar } from '../../../../../state/application/applicationAction';
import { closePanel } from '../../../state/entregasAction';

export const fetchFormCreateEntregasData = createAsyncThunk(
  'formCreateEntregas/fetch',
  async () => {
    const notas = await getNotaByOcorrencia(85);
    return notas;
  }
);

export const createEntrega = createAsyncThunk(
  'formCreateEntregas/createEntrega',
  async (observacao: string, thunkApi) => {
    thunkApi.dispatch(saving(true));
    const state = thunkApi.getState() as RootState;
    await EntregaService.createEntrega(
      state.formCreateEntregas.data.selectedNotas,
      observacao
    );
    thunkApi.dispatch(resetSelectedNotas());
    thunkApi.dispatch(
      openSnackbar({
        message: 'Entrega registrada com sucesso',
        severity: 'success',
      })
    );
    thunkApi.dispatch(saving(false));
    thunkApi.dispatch(closeCreatePanel());
    thunkApi.dispatch(fetchEntregasData());
  }
);
