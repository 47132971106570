import { createSlice, current } from '@reduxjs/toolkit';
import { Cliente } from '../../../../../data/types/Cliente';
import { Entrega } from '../../../../../data/types/Entrega';
import { Nota } from '../../../../../data/types/Nota';
import { fetchFormCreateEntregasData } from './formCreateEntregasFetch';

export interface FormCreateEntregasState {
  isLoading: boolean;
  isSaving: boolean;
  openPanel: boolean;
  data: {
    notas: Nota[];
    destinatarios: Cliente[];
    selectedNotas: Nota[];
  };
}
const initialState = {
  isLoading: false,
  isSaving: false,
  openPanel: false,
  data: {
    notas: [],
    destinatarios: [],
    selectedNotas: [],
  },
} as FormCreateEntregasState;

const formCreateEntregasSlice = createSlice({
  name: 'formCreateEntregas',
  initialState,
  reducers: {
    openCreatePanel: (state) => {
      state.openPanel = true;
    },
    closeCreatePanel: (state) => {
      state.openPanel = false;
    },
    addSelectedNota: (state, action) => {
      state.data.selectedNotas.push(action.payload);
    },
    removeSelectedNota: (state, action) => {
      state.data.selectedNotas = state.data.selectedNotas.filter(
        (nota) => nota.idNota !== action.payload.idNota
      );
    },
    resetSelectedNotas: (state) => {
      state.data.selectedNotas = [];
    },
    saving: (state, action) => {
      state.isSaving = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormCreateEntregasData.pending.type, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFormCreateEntregasData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data.notas = action.payload;
      state.data.destinatarios = action.payload
        .map((nota) => {
          return {
            idCliente: nota.idDestinatario,
            nome: `${nota.destinatario} - ${nota.cidade} - ${nota.cnpjDestinatario}`,
            cnpj: nota.cnpjDestinatario,
          };
        })
        .reduce((uniqueDestinatarios, currentDestinatario) => {
          const exists = uniqueDestinatarios.some(
            (destinatario) =>
              destinatario.idCliente === currentDestinatario.idCliente
          );

          if (!exists) {
            uniqueDestinatarios.push(currentDestinatario as Cliente);
          }

          return uniqueDestinatarios;
        }, [] as Cliente[])
        .sort((a, b) => a.nome.localeCompare(b.nome));
    });
  },
});
export default formCreateEntregasSlice.reducer;
