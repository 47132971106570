export enum NotaType {
  SUBC_REC_FORM_LISO = 'SUBC REC FORM LISO',
  NORMAL = 'NORMAL',
  CARGA_FECHADA = 'CARGA FECHADA',
  DEVOLUCAO = 'DEVOLUCAO',
  SUBC_EXP_FORM_LISO = 'SUBC EXP FORM LISO',
  SUBC_FORM_CTRC = 'SUBC FORM CTRC',
  SUBC_FEC_FORM_CTRC = 'SUBC FEC FORM CTRC',
  REVERSA = 'REVERSA',
  CORTESIA = 'CORTESIA',
  REDESPACHO = 'REDESPACHO',
  SUBSTITUTO = 'SUBSTITUTO',
}
