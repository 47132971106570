import { useEffect, useState } from 'react';
import CardEntrega from './CardEntrega';
import { Entrega } from '../../../data/types/Entrega';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../state/store';
import Loading from '../../../components/Loading';
import { useDispatch } from 'react-redux';
import { registrarEntrega } from '../state/entregasFetch';
import { TextField } from '@mui/material';
import { Transition } from '@headlessui/react';

type EntretaProps = {
  entregas: Entrega[];
  openOpenPanel: Function;
};

const PainelEntregas = ({ entregas, openOpenPanel }: EntretaProps) => {
  const [filtro, setFiltro] = useState('');
  const [entregasFiltered, setEntregasFiltered] = useState<Entrega[]>([]);

  useEffect(() => {
    if (filtro !== '') {
      setEntregasFiltered(
        entregas.filter((entrega) => {
          return (
            entrega.notas.filter((nota) =>
              nota.numeroNotaFiscal.includes(filtro)
            ).length > 0 ||
            entrega.cliente.nome.toUpperCase().includes(filtro.toUpperCase())
          );
        })
      );
    } else {
      setEntregasFiltered(entregas);
    }
  }, [filtro]);

  const dispatch: AppDispatch = useDispatch();

  const registrar = (idEntrega: number, status: string, observacao: string) => {
    dispatch(registrarEntrega({ idEntrega, status, observacao }));
  };

  const isLoading = useSelector((state: RootState) => state.entregas.isLoading);

  if (isLoading) {
    return <Loading />;
  }

  console.log(entregas);

  return (
    <div className={`flex flex-col`}>
      <div className={`p-2 min-h-[100px] `}>
        <TextField
          sx={{ '& .MuiFormHelperText-root': { color: 'red' } }}
          onChange={(e) => setFiltro(e.target.value)}
          fullWidth
          label='Filtrar por NF ou Destinatário'
          helperText={
            entregasFiltered.length === 0 && filtro !== ''
              ? 'Nenhum resultado encontrado'
              : ''
          }
        />
      </div>
      <div className='grid h-full md:grid-cols-entrega overflow-auto gap-4 content-start justify-center'>
        {(entregasFiltered.length > 0 ? entregasFiltered : entregas).map(
          (entrega, index) => {
            return (
              <Transition
                key={index}
                show={
                  entrega.status == 'aguardando' || entrega.status == 'iniciada'
                }
                enter='transition duration-[200ms] delay-200'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition duration-1000'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <CardEntrega
                  key={index}
                  entrega={entrega}
                  registrar={registrar}
                  openOpenPanel={(entrega: Entrega) => openOpenPanel(entrega)}
                />
              </Transition>
            );
          }
        )}
      </div>
    </div>
  );
};
export default PainelEntregas;
