import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import NumeroNotaFiscal from '../../../../../components/NumeroNotaFiscal';
import { Nota } from '../../../../../data/types/Nota';
import { Checkbox } from '@mui/material';

export const columns = (
  addSelectedNota: Function,
  removeSelectedNota: Function
) =>
  [
    {
      field: 'idNota',
      headerName: '',
      flex: 0.5,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Checkbox
            color='primary'
            onChange={(event) => {
              if (event.target.checked) {
                addSelectedNota(row);
              } else {
                removeSelectedNota(row);
              }
            }}
          />
        );
      },
    },
    {
      field: 'numeroNotaFiscal',
      headerName: 'NF',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return <NumeroNotaFiscal nota={row} />;
      },
    },
    {
      field: 'remetente',
      headerName: 'Remetente',
      flex: 2,
    },
  ] as GridColDef<Nota>[];
