import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getNotaById,
  getNotaByNumeroNotaFiscal,
  getNotasOcorrencia,
} from '../../../services/NotaService';
import { getAcompanhamento } from '../../../services/AcompanhamentoService';
import { openNotaTab } from './notaAction';

export const fetchNotaDataById = createAsyncThunk(
  'nota/fetchById',
  async (idNota: number, thunkApi) => {
    thunkApi.dispatch(openNotaTab());
    return await getNotaById(idNota).then(async (nota) => {
      const ocorrencias = await getNotasOcorrencia(idNota);
      const acompanhamentos = await getAcompanhamento(idNota);

      return {
        nota,
        ocorrencias,
        acompanhamentos,
      };
    });
  }
);

export const fetchNotaDataByNumeroNotaFiscal = createAsyncThunk(
  'nota/fetchByNumeroNotaFiscal',
  async (numeroNotaFiscal: number, thunkApi) => {
    thunkApi.dispatch(openNotaTab());
    return await getNotaByNumeroNotaFiscal(numeroNotaFiscal).then(
      async (nota) => {
        const ocorrencias = await getNotasOcorrencia(nota.idNota);
        const acompanhamentos = await getAcompanhamento(nota.idNota);

        return {
          nota,
          ocorrencias,
          acompanhamentos,
        };
      }
    );
  }
);
