import { createAction } from '@reduxjs/toolkit';

export const changeUnidadeFilter = createAction<string[]>(
  'entregas/changeUnidadeFilter'
);

export const openPanel = createAction('entregas/openPanel');
export const closePanel = createAction('entregas/closePanel');
export const openPanelEntrega = createAction('entregas/openPanelEntrega');
export const closePanelEntrega = createAction('entregas/closePanelEntrega');
export const registrarEntrega = createAction<{
  idEntrega: number;
  status: string;
}>('entregas/registrarEntrega');
