import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Nota } from '../../../data/types/Nota';
import { Acompanhamento } from '../../../data/types/Acompanhamento';
import * as AcompanhamentoService from './../../../services/AcompanhamentoService';
import { openSnackbar } from '../../../state/application/applicationAction';
import { fetchNotaDataById } from './notaFetch';

export const openNotaTab = createAction('nota/openNotaTab');
export const closeNotaTab = createAction('nota/closeNotaTab');

export const createAcompanhamento = createAsyncThunk(
  'nota/createAcompanhamento',
  async (acompanhamento: Acompanhamento, { dispatch }) => {
    await AcompanhamentoService.createAcompanhamento(
      acompanhamento.idNota,
      acompanhamento.descricao
    );
    dispatch(fetchNotaDataById(acompanhamento.idNota)).then(() => {
      dispatch(
        openSnackbar({
          message: 'Acompanhamento salvo com sucesso',
          severity: 'success',
        })
      );
    });
  }
);

createAcompanhamento;
