import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { formatDate } from '../../../utils/formatter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import Loading from '../../../components/Loading';

const Ocorrencias = () => {
  const ocorrencias = useSelector(
    (state: RootState) => state.nota.data.ocorrencias
  );

  if (ocorrencias.length === 0) {
    return <Loading />;
  } else {
    return (
      <div>
        <TableContainer>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
              },
            }}
          >
            <TableRow>
              <TableCell>
                <span className='font-bold'>Ocorrência</span>
              </TableCell>
              <TableCell>
                <span className='font-bold'>Data</span>
              </TableCell>
              <TableCell>
                <span className='font-bold'>Descrição</span>
              </TableCell>
            </TableRow>
            {ocorrencias.map((ocorrencia, index) => (
              <TableRow key={index}>
                <TableCell>{ocorrencia.codigo}</TableCell>
                <TableCell>{formatDate(ocorrencia.data)}</TableCell>
                <TableCell>{ocorrencia.descricao}</TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </div>
    );
  }
};
export default Ocorrencias;
