import { createAction } from '@reduxjs/toolkit';
import { Nota } from '../../../../../data/types/Nota';
import { Entrega } from '../../../../../data/types/Entrega';

export const openOpenPanel = createAction<Entrega>(
  'formOpenEntrega/openOpenPanel'
);
export const closeOpenPanel = createAction('formOpenEntrega/closeOpenPanel');
export const addSelectedNota = createAction<Nota>(
  'formOpenEntrega/addSelectedNota'
);
export const removeSelectedNota = createAction<Nota>(
  'formOpenEntrega/removeSelectedNota'
);
