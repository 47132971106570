import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import {
  formatCurrency,
  formatDate,
  formatPeso,
} from '../../../utils/formatter';
import Loading from '../../../components/Loading';
import StatusNota from '../../../components/StatusNota';

const DetalhesNota = () => {
  const nota = useSelector((state: RootState) => state.nota.data.nota);
  const cliente = useSelector(
    (state: RootState) => state.parameter.data.clientes
  ).find((c) => c.cnpj !== undefined && c.cnpj === nota?.cnpjPagadorFrete);

  if (nota === undefined) {
    return <Loading />;
  } else {
    return (
      <TableContainer>
        <Table
          className='w-[200px]'
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Status:</span>
            </TableCell>
            <TableCell>
              <span className='text-md uppercase'>{nota.status}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Em Trânsito:</span>
            </TableCell>
            <TableCell>
              <span className='text-md uppercase'>
                {nota.transito ? 'Sim' : 'Não'}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Tipo:</span>
            </TableCell>
            <TableCell>
              <span className='text-md uppercase'>{nota.tipoDocumento}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Nota Fiscal:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>{nota.numeroNotaFiscal}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>CTRC:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>{nota.ctrc}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Peso Real / Cubado:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>
                {formatPeso(nota.pesoReal, 2)} /{' '}
                {formatPeso(nota.pesoCubado, 2)}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Valor do Frete:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>
                {formatCurrency(nota.valorFrete, 2)}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Valor da Mercadoria:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>
                {formatCurrency(nota.valorMercadoria, 2)}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Data Emissão:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>
                {formatDate(nota.dataAutorizacao)}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Data Limite:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>
                <StatusNota nota={nota} />
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Remetente:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>{nota.remetente}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Destinatário:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>{nota.destinatario}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Tomador de Frete:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>{cliente?.nome}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Cidade:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>{nota.cidade}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <span className='font-bold text-md'>Romaneio:</span>
            </TableCell>
            <TableCell>
              <span className='text-md'>{nota.codigoRomaneio}</span>
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    );
  }
};

export default DetalhesNota;
