import { Entrega } from '../data/types/Entrega';
import { Nota } from '../data/types/Nota';
import {
  getCurrentDateInBRTFormat,
  getCurrentDateTimeInBRTFormat,
} from '../utils/date';
import { postRequest, getRequest } from './service';

export const createEntrega = async (
  notas: Nota[],
  observacao: string
): Promise<Nota[]> => {
  return await postRequest<any>('/entrega', {
    notas: notas.map((nota) => {
      return {
        idNota: nota.idNota,
        cnpjPagadorFrete: nota.cnpjPagadorFrete,
        cnpjDestinatario: nota.cnpjDestinatario,
        idCidade: nota.idCidade,
      };
    }),
    data: getCurrentDateTimeInBRTFormat(),
    observacao,
  });
};

export const removerNotas = async (
  idEntrega: number,
  notas: Nota[],
  observacao: string
): Promise<Nota[]> => {
  return await postRequest<any>(`/entrega/${idEntrega}/remover-notas`, {
    idNotas: notas.map((nota) => nota.idNota),
    observacao,
  });
};

export const registrarEntrega = async (
  idEntrega: number,
  status: string,
  observacao: string
): Promise<Nota[]> => {
  return await postRequest<any>(`/entrega/${idEntrega}/registro`, {
    status,
    observacao,
    data: getCurrentDateTimeInBRTFormat(),
  });
};
