import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Button, Checkbox, Tooltip } from '@mui/material';
import { Nota } from '../../../data/types/Nota';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import NotaFiscal from '../../../components/NotaFiscal';
import { AppDispatch } from '../../../state/store';
import { openNotaTab } from '../../nota/state/notaAction';
import { Cliente } from '../../../data/types/Cliente';
import { fetchNotaDataById } from '../../nota/state/notaFetch';

export const columns = (
  dispatch: AppDispatch,
  addSelectedNota: Function,
  removeSelectedNota: Function,
  clientes: Cliente[],
  handleOpenFormUpdateCliente: Function
) =>
  [
    {
      field: 'idNota',
      headerName: '',
      flex: 0.5,
      renderCell: ({ row }: GridRenderCellParams) => {
        if (
          !clientes.find((cliente) => cliente.cnpj === row.cnpjPagadorFrete)
            ?.emailProtocolo
        ) {
          return <></>;
        } else {
          return (
            <Checkbox
              color='primary'
              onChange={(event) => {
                if (event.target.checked) {
                  addSelectedNota(row);
                } else {
                  removeSelectedNota(row);
                }
              }}
            />
          );
        }
      },
    },
    {
      field: 'numeroNotaFiscal',
      headerName: 'NF',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <div className='text-[#3B82F6] flex'>
            <button onClick={() => dispatch(fetchNotaDataById(row.idNota))}>
              <div className='flex gap-1 items-center'>
                <FaMagnifyingGlass />
                <NotaFiscal numeroNotaFiscal={row.numeroNotaFiscal} />
              </div>
            </button>
          </div>
        );
      },
    },
    { field: 'codigoRomaneio', headerName: 'Romaneio', flex: 1 },
    { field: 'remetente', headerName: 'Remetente', flex: 2 },
    { field: 'destinatario', headerName: 'Destinatario', flex: 2 },
    { field: 'cidade', headerName: 'Cidade', flex: 1 },
    {
      field: 'cnpjPagadorFrete',
      headerName: 'Email de Protocolo',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        if (
          !clientes.find((cliente) => cliente.cnpj === row.cnpjPagadorFrete)
            ?.emailProtocolo
        ) {
          return (
            <Button
              onClick={() =>
                handleOpenFormUpdateCliente(
                  clientes.find(
                    (cliente) => cliente.cnpj === row.cnpjPagadorFrete
                  )
                )
              }
              size='small'
              variant={`text`}
              color='primary'
            >
              CADASTRAR
            </Button>
          );
        } else {
          return (
            <Button
              onClick={() =>
                handleOpenFormUpdateCliente(
                  clientes.find(
                    (cliente) => cliente.cnpj === row.cnpjPagadorFrete
                  )
                )
              }
              size='small'
              variant={`text`}
              color='primary'
            >
              EDITAR
            </Button>
          );
        }
        return <></>;
      },
    },
  ] as GridColDef<Nota>[];
