import { createSlice } from '@reduxjs/toolkit';
import { Nota } from '../../../data/types/Nota';
import { Ocorrencia } from '../../../data/types/Ocorrencia';
import { Acompanhamento } from '../../../data/types/Acompanhamento';
import {
  fetchNotaDataById,
  fetchNotaDataByNumeroNotaFiscal,
} from './notaFetch';

export interface NotaState {
  isOpen: boolean;
  isLoading: boolean;
  data: {
    nota: Nota | undefined;
    ocorrencias: Ocorrencia[];
    acompanhamentos: Acompanhamento[];
  };
}

const initialState = {
  isOpen: false,
  isLoading: false,
  data: {
    nota: undefined,
    ocorrencias: [] as Ocorrencia[],
    acompanhamentos: [] as Acompanhamento[],
  },
} as NotaState;

const notaSlice = createSlice({
  name: 'nota',
  initialState,
  reducers: {
    openNotaTab: (state, action) => {
      state.isOpen = true;
    },
    closeNotaTab: (state) => {
      state.isOpen = false;
      state.data.nota = undefined;
      state.data.ocorrencias = [];
      state.data.acompanhamentos = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotaDataById.pending.type, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchNotaDataById.fulfilled, (state, action) => {
      state.data.ocorrencias = action.payload.ocorrencias;
      state.data.acompanhamentos = action.payload.acompanhamentos;
      state.data.nota = action.payload.nota;
      state.isLoading = false;
    });

    builder.addCase(fetchNotaDataByNumeroNotaFiscal.pending.type, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchNotaDataByNumeroNotaFiscal.fulfilled,
      (state, action) => {
        state.data.ocorrencias = action.payload.ocorrencias;
        state.data.acompanhamentos = action.payload.acompanhamentos;
        state.data.nota = action.payload.nota;
        state.isLoading = false;
      }
    );
  },
});
export default notaSlice.reducer;
