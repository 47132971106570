import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNotaByOcorrencia } from '../../../../../services/NotaService';
import * as EntregaService from '../../../../../services/EntregaService';
import { Nota } from '../../../../../data/types/Nota';
import { openSnackbar } from '../../../../../state/application/applicationAction';
import { closeOpenPanel } from './formOpenEntregaAction';
import { fetchEntregasData } from '../../../state/entregasFetch';

export const fetchFormCreateEntregasData = createAsyncThunk(
  'formOpenEntrega/fetch',
  async (_, thunkApi) => {}
);

export const removerNotas = createAsyncThunk(
  'formOpenEntrega/removerNotas',
  async (
    payload: { idEntrega: number; observacao: string; notas: Nota[] },
    thunkApi
  ) => {
    await EntregaService.removerNotas(
      payload.idEntrega,
      payload.notas,
      payload.observacao
    );

    thunkApi.dispatch(
      openSnackbar({
        message: 'Notas removidas com sucesso.',
        severity: 'success',
      })
    );
    thunkApi.dispatch(closeOpenPanel());
    thunkApi.dispatch(fetchEntregasData());
  }
);
