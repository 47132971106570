import { createSlice, current } from '@reduxjs/toolkit';
import { Cliente } from '../../../../../data/types/Cliente';
import { Entrega } from '../../../../../data/types/Entrega';
import { Nota } from '../../../../../data/types/Nota';
import { fetchFormCreateEntregasData } from './formOpenEntregaFetch';

export interface FormCreateEntregasState {
  openPanel: boolean;
  data: {
    entrega: Entrega | null;
    selectedNotas: Nota[];
  };
}
const initialState = {
  openPanel: false,
  data: {
    entrega: null,
    selectedNotas: [],
  },
} as FormCreateEntregasState;

const formOpenEntregaSlice = createSlice({
  name: 'formOpenEntrega',
  initialState,
  reducers: {
    openOpenPanel: (state, action) => {
      state.data.entrega = action.payload;
      state.openPanel = true;
    },
    closeOpenPanel: (state) => {
      state.openPanel = false;
      state.data.selectedNotas = [];
      state.data.entrega = null;
    },
    addSelectedNota: (state, action) => {
      state.data.selectedNotas.push(action.payload);
    },
    removeSelectedNota: (state, action) => {
      state.data.selectedNotas = state.data.selectedNotas.filter(
        (nota) => nota.idNota !== action.payload.idNota
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormCreateEntregasData.pending.type, (state) => {});
    builder.addCase(
      fetchFormCreateEntregasData.fulfilled,
      (state, action) => {}
    );
  },
});
export default formOpenEntregaSlice.reducer;
