import Profile from './Profile';
import { AppDispatch, RootState } from '../../../state/store';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { Menu, MenuOpen } from '@mui/icons-material';
import { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { MdAccountCircle } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { openNotaTab } from '../../../pages/nota/state/notaAction';
import { useDispatch } from 'react-redux';
import { fetchNotaDataByNumeroNotaFiscal } from '../../../pages/nota/state/notaFetch';

type HeaderProps = {
  setSideBarWidth: Function;
  sideBarWidth: string;
};

const Header = ({ setSideBarWidth, sideBarWidth }: HeaderProps) => {
  const [searchValue, setSearchValue] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const handleSidebar = () => {
    if (sideBarWidth === '80') {
      setSideBarWidth('250');
    } else {
      setSideBarWidth('80');
    }
  };

  const handleNota = (event: any) => {
    if (event.key === 'Enter') {
      dispatch(fetchNotaDataByNumeroNotaFiscal(event.target.value));
      setSearchValue('');
    }
  };

  return (
    <div className='h-[60px] shadow-md bg-white flex items-center overflow-hidden'>
      <div
        className='md:block pl-5 cursor-pointer'
        onClick={() => handleSidebar()}
      >
        {sideBarWidth === '80' ? <Menu /> : <MenuOpen />}
      </div>
      <div className='pl-10 '>
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          placeholder='Buscar nota'
          InputProps={{
            startAdornment: <BiSearch fontSize='large' className='mr-3' />,
            disableUnderline: true,
          }}
          onKeyDown={handleNota}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className='ml-auto pr-6 cursor-pointer flex items-center'>
        <Profile />
      </div>
    </div>
  );
};
export default Header;
