import {
  Button,
  Chip,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import { FaTruck } from 'react-icons/fa';
import { Entrega } from '../../../data/types/Entrega';
import React from 'react';
import NotaFiscal from '../../../components/NotaFiscal';
import NumeroNotaFiscal from '../../../components/NumeroNotaFiscal';
import { formatDataHora } from '../../../utils/formatter';
import { useDispatch } from 'react-redux';
import { MdMail } from 'react-icons/md';

type CardEntregaProps = {
  entrega: Entrega;
  registrar: (id: number, status: string, observacao: string) => any;
  openOpenPanel: Function;
};

const acoes = [
  {
    nome: 'iniciar',
    status: 'iniciada',
    style: 'contained',
    color: 'primary',
    bgColor: 'bg-white',
  },
  {
    nome: 'não finalizada',
    status: 'não finalizada',
    style: 'outlined',
    color: 'error',
    bgColor: 'bg-red-500',
  },
  {
    nome: 'finalizar',
    status: 'finalizada',
    style: 'contained',
    color: 'success',
    bgColor: 'bg-green-500',
  },
  {
    nome: 'reabrir',
    status: 'aguardando',
    style: 'outlined',
    color: 'primary',
    bgColor: 'bg-white',
  },
];

const steps = [
  {
    status: 'aguardando',
    acoes: ['iniciar', 'não finalizada'],
    color: 'error',
  },
  {
    status: 'iniciada',
    acoes: ['finalizar', 'reabrir', 'não finalizada'],
    color: 'primary',
  },
  {
    status: 'finalizada',
    acoes: ['iniciar', 'reabrir'],
    color: 'success',
  },
  {
    status: 'não finalizada',
    acoes: ['iniciar', 'reabrir'],
    color: 'error',
  },
];

const CardEntrega = ({
  entrega,
  registrar,
  openOpenPanel,
}: CardEntregaProps) => {
  const dispatch = useDispatch();
  const [observacao, setObservacao] = React.useState('');

  return (
    <div
      key={entrega.idEntrega}
      className={`rounded-md shadow-md bg-white rounded-t-lg flex flex-col items-center w-[500px] gap-5 border-gray-200 border-solid border`}
    >
      <div className='truncate ... bg-primary-500 w-full p-2 rounded-t-lg text-gray-100 font-bold'>
        {entrega.cliente.nome} - {entrega.cidade.nome}
      </div>
      <div className='p-5 w-full flex gap-5 flex-col items-center'>
        <div className={`w-full flex justify-center gap-2`}>
          <div className={`space-x-4 font-bold text-2xl`}>
            <span className={``}>{formatDataHora(entrega.dataEntrega)}</span>
            <span>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => openOpenPanel(entrega)}
              >
                {entrega.notas.length}{' '}
                {entrega.notas.length === 1
                  ? 'Nota +Detalhe'
                  : 'Notas +Detalhes'}
              </Button>
            </span>
          </div>
        </div>

        <Stepper
          activeStep={
            entrega.status === 'não finalizada'
              ? -1
              : steps.findIndex((step) => step.status === entrega.status)
          }
          className='w-full px-5'
        >
          {steps.map((step, index) => {
            let stepStatus = step.status;
            if (step.status === 'não finalizada') {
              return null;
            }
            if (
              step.status === 'finalizada' &&
              entrega.status === 'não finalizada'
            ) {
              stepStatus = 'não finalizada';
            }
            return (
              <Step key={index}>
                {step.status === entrega.status ? (
                  <StepLabel
                    StepIconComponent={() => (
                      <FaTruck size={30} className='text-primary-600' />
                    )}
                  >
                    <div className={``}>
                      <Chip
                        label={stepStatus}
                        color={step.color as 'primary' | 'success' | 'error'}
                        variant='filled'
                      />
                    </div>
                  </StepLabel>
                ) : (
                  <StepLabel>{stepStatus}</StepLabel>
                )}
              </Step>
            );
          })}
        </Stepper>
        <div className='w-full'>
          <TextField
            multiline
            rows={2}
            label='Observação'
            id='observacao'
            name='observacao'
            fullWidth
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />
        </div>
        <div className='flex gap-2'>
          {steps
            .find((step) => step.status === entrega.status)
            ?.acoes.map((acaoString, index) => {
              const acao = acoes.find((acao) => acao.nome === acaoString);
              if (!acao) {
                return null;
              }

              return (
                <Button
                  key={index}
                  variant={acao.style as 'text' | 'contained' | 'outlined'}
                  color={acao.color as 'primary' | 'success' | 'error'}
                  onClick={() => {
                    registrar(entrega.idEntrega, acao.status, observacao);
                    setObservacao('');
                  }}
                >
                  <div className={`flex gap-2 justify-center items-center`}>
                    <span className={`text-lg`}>
                      <MdMail />
                    </span>
                    <span>{acao.nome}</span>
                  </div>
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default CardEntrega;
