import { Nota } from '../../../data/types/Nota';
import { BsCurrencyDollar, BsExclamationLg } from 'react-icons/bs';
import { IoDocumentOutline } from 'react-icons/io5';
import { getFrete, getNotasVencidas } from '../../../utils/nota';
import TableAtrasadas from '../../dashboard/components/TableAtrasadas';
import { NotaType } from '../../../data/enums/NotaType';
import Card from '../../../components/Card';

type SummaryProps = {
  notas: Nota[];
  unidadeAtual: string;
};

const Summary = (props: SummaryProps) => {
  const notas = props.notas;
  const unidadeAtual = props.unidadeAtual;
  return (
    <div className='p-10 flex flex-col space-y-4'>
      <div className='grid grid-cols-3 '>
        <Card title='Frete Recepção' icon={<BsCurrencyDollar />}>
          <div className='text-green-600'>
            {getFrete(
              notas.filter((nota) => nota.rota?.unidade.nome === unidadeAtual),
              2
            )}
          </div>
        </Card>
        <Card title='Frete Expedição' icon={<BsCurrencyDollar />}>
          <div className='text-green-600'>
            {getFrete(
              notas.filter((nota) => nota.rota?.unidade.nome != unidadeAtual),
              2
            )}
          </div>
        </Card>

        <Card title='Notas' icon={<IoDocumentOutline />}>
          {notas.length}
        </Card>
      </div>

      <TableAtrasadas title='Notas Atrasadas' icon={<BsExclamationLg />}>
        <div className='my-5 grid grid-cols-12'>
          <div className='pr-10 text-xl col-span-6 font-extrabold'>
            Entregas
          </div>
          <div className='text-vencido text-2xl col-span-4 font-extrabold flex'>
            <div className=''>
              {
                getNotasVencidas(
                  notas.filter((nota) => nota.tipoDocumento === NotaType.NORMAL)
                ).length
              }
            </div>
          </div>
        </div>
        <div className='my-5 grid grid-cols-12'>
          <div className='pr-10 text-xl col-span-6 font-extrabold'>
            Devolução
          </div>
          <div className='text-vencido text-2xl col-span-4 font-extrabold flex'>
            <div className=''>
              {
                getNotasVencidas(
                  notas.filter(
                    (nota) => nota.tipoDocumento === NotaType.DEVOLUCAO
                  )
                ).length
              }
            </div>
          </div>
        </div>
        <div className='my-5 grid grid-cols-12'>
          <div className='pr-10 text-xl col-span-6 font-extrabold'>
            Coleta Reversa
          </div>
          <div className='text-vencido text-2xl col-span-4 font-extrabold flex'>
            <div className=''>
              {
                getNotasVencidas(
                  notas.filter(
                    (nota) => nota.tipoDocumento === NotaType.REVERSA
                  )
                ).length
              }
            </div>
          </div>
        </div>
        <div className='my-5 grid grid-cols-12'>
          <div className='pr-10 text-xl col-span-6 font-extrabold'>Total</div>
          <div className='text-vencido text-2xl col-span-4 font-extrabold flex'>
            <div className=''>{getNotasVencidas(notas).length}</div>
          </div>
        </div>
      </TableAtrasadas>
    </div>
  );
};
export default Summary;
