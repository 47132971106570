import { Button, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import PainelEntregas from './components/PainelEntregas';
import FormCreateEntregas from './components/formCreateEntregas/FormCreateEntregas';
import { AppDispatch, RootState } from '../../state/store';
import { useDispatch } from 'react-redux';
import { fetchEntregasData } from './state/entregasFetch';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import { closePanel, openPanel } from './state/entregasAction';
import { CiCirclePlus } from 'react-icons/ci';
import FormOpenEntrega from './components/formOpenEntrega/FormOpenEntrega';
import {
  closeCreatePanel,
  openCreatePanel,
} from './components/formCreateEntregas/state/formCreateEntregasAction';
import {
  closeOpenPanel,
  openOpenPanel,
} from './components/formOpenEntrega/state/formOpenEntregaAction';
import { Entrega } from '../../data/types/Entrega';

enum Panel {
  CREATE = 'create',
  OPEN = 'open',
}

// TODO: se tiver a placa do carro e motorista, mostrar no card
const Entregas = () => {
  const dispatch: AppDispatch = useDispatch();

  const isOpenPanel = useSelector(
    (state: RootState) => state.formCreateEntregas.openPanel
  );

  const isOpenPanelEntrega = useSelector(
    (state: RootState) => state.formOpenEntrega.openPanel
  );

  React.useEffect(() => {
    dispatch(fetchEntregasData());
  }, []);

  const entregas = useSelector(
    (state: RootState) => state.entregas.data.entregas
  );

  const handleClosePanel = (panel: Panel) => {
    switch (panel) {
      case Panel.CREATE:
        dispatch(closeCreatePanel());
        break;
      case Panel.OPEN:
        dispatch(closeOpenPanel());
        break;
      default:
        break;
    }
  };

  const handleOpenPanel = (panel: Panel, entrega: Entrega | null = null) => {
    switch (panel) {
      case Panel.CREATE:
        dispatch(openCreatePanel());
        handleClosePanel(Panel.OPEN);
        break;
      case Panel.OPEN:
        if (entrega !== null) {
          dispatch(openOpenPanel(entrega));
          handleClosePanel(Panel.CREATE);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className='p-5 w-full h-full overflow-hidden'>
      <span className='text-2xl flex gap-5'>
        <div>Entregas</div>
        <div className='ml-auto'>
          <Button variant='text' onClick={() => handleOpenPanel(Panel.CREATE)}>
            <div className='flex items-center gap-2'>
              <CiCirclePlus className='text-[30px]' />
              Nova Entrega
            </div>
          </Button>
        </div>
      </span>
      <div
        className={`grid ${
          isOpenPanel || isOpenPanelEntrega
            ? 'grid-cols-[40%_60%]'
            : 'grid-cols-[100%_0px]'
        } gap-5 h-full transition-all duration-900`}
      >
        <div className='overflow-auto'>
          <PainelEntregas
            entregas={entregas}
            openOpenPanel={(entrega: Entrega) =>
              handleOpenPanel(Panel.OPEN, entrega)
            }
          />
        </div>
        {isOpenPanel && (
          <FormCreateEntregas cancelar={() => handleClosePanel(Panel.CREATE)} />
        )}
        {isOpenPanelEntrega && (
          <FormOpenEntrega cancelar={() => handleClosePanel(Panel.OPEN)} />
        )}
      </div>
    </div>
  );
};

export default Entregas;
