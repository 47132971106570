import { createAction } from '@reduxjs/toolkit';
import { Nota } from '../../../../../data/types/Nota';

export const addSelectedNota = createAction<Nota>(
  'formCreateEntregas/addSelectedNota'
);
export const removeSelectedNota = createAction<Nota>(
  'formCreateEntregas/removeSelectedNota'
);
export const resetSelectedNotas = createAction(
  'formCreateEntregas/resetSelectedNotas'
);

export const openCreatePanel = createAction(
  'formCreateEntregas/openCreatePanel'
);

export const closeCreatePanel = createAction(
  'formCreateEntregas/closeCreatePanel'
);

export const saving = createAction<boolean>('formCreateEntregas/saving');
