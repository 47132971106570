import { FaMagnifyingGlass } from 'react-icons/fa6';
import NotaFiscal from './NotaFiscal';
import { Nota } from '../data/types/Nota';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../state/store';
import { openNotaTab } from '../pages/nota/state/notaAction';
import { fetchNotaDataById } from '../pages/nota/state/notaFetch';

type NumeroNotaFiscalProps = {
  nota: Nota;
};

const NumeroNotaFiscal = ({ nota }: NumeroNotaFiscalProps) => {
  const dispatch: AppDispatch = useDispatch();
  return (
    <div className='text-[#3B82F6] flex'>
      <button onClick={() => dispatch(fetchNotaDataById(nota.idNota))}>
        <div className='flex gap-1 items-center'>
          <FaMagnifyingGlass />
          <NotaFiscal numeroNotaFiscal={nota.numeroNotaFiscal} />
        </div>
      </button>
    </div>
  );
};
export default NumeroNotaFiscal;
