import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginaEntregas } from '../../../services/PaginaService';
import * as EntregaService from '../../../services/EntregaService';
import * as EntregaAction from './entregasAction';
import { openSnackbar } from '../../../state/application/applicationAction';
import { closeCreatePanel } from '../components/formCreateEntregas/state/formCreateEntregasAction';

export const fetchEntregasData = createAsyncThunk(
  'entregas/fetch',
  async () => {
    const entregas = await getPaginaEntregas();
    return entregas;
  }
);

export const registrarEntrega = createAsyncThunk(
  'entregas/registrarEntrega',
  async ({ idEntrega, status, observacao }: any, thunkApi) => {
    await EntregaService.registrarEntrega(idEntrega, status, observacao)
      .then((data) => {})
      .catch((error) => {
        thunkApi.dispatch(
          openSnackbar({
            message: 'Ocorreu um erro ao tentar registrar a entrega.',
            severity: 'error',
          })
        );
      });

    thunkApi.dispatch(
      openSnackbar({
        message: 'Entrega registrada com sucesso',
        severity: 'success',
      })
    );
    thunkApi.dispatch(closeCreatePanel());
    thunkApi.dispatch(EntregaAction.registrarEntrega({ idEntrega, status }));
  }
);
