import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Nota } from '../../../../../data/types/Nota';
import { columns } from './columns';
import { Button, MenuItem, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  addSelectedNota,
  removeSelectedNota,
} from '../state/formOpenEntregaAction';
import { useSelector } from 'react-redux';
import { stat } from 'fs';
import { AppDispatch, RootState } from '../../../../../state/store';
import { MdMail } from 'react-icons/md';
import { removerNotas } from '../state/formOpenEntregaFetch';

type NotasGridProps = {
  notas: Nota[];
  cancelar: () => void;
  observacao: string;
};

const CustomPagination = ({
  cancelar,
  notas,
  observacao,
  selectedNotas,
  dispatch,
  entrega,
}: any) => {
  return (
    <div className='flex items-center w-full'>
      <div style={{ flexGrow: 1 }}></div>
      <div className='px-2'>
        <span className='flex gap-5'>
          <Button onClick={() => cancelar()}>Cancelar</Button>
          {notas.length > selectedNotas.length && notas.length > 1 ? (
            <Button
              disabled={
                notas.length <= 1 ||
                observacao.length === 0 ||
                selectedNotas.length === 0
              }
              variant='contained'
              color='error'
              onClick={() =>
                dispatch(
                  removerNotas({
                    idEntrega: entrega.idEntrega,
                    observacao,
                    notas: selectedNotas,
                  })
                )
              }
            >
              <div className={`flex gap-2 justify-center items-center`}>
                <span className={`text-lg`}>
                  <MdMail />
                </span>
                <span>
                  {selectedNotas.length > 1 ? 'Remover Notas' : 'Remover Nota'}
                </span>
              </div>
            </Button>
          ) : (
            <Button
              variant='contained'
              color='error'
              onClick={() =>
                dispatch(
                  removerNotas({
                    idEntrega: entrega.idEntrega,
                    observacao,
                    notas: selectedNotas,
                  })
                )
              }
              disabled={selectedNotas.length === 0 || observacao.length === 0}
            >
              <div className={`flex gap-2 justify-center items-center`}>
                <span className={`text-lg`}>
                  <MdMail />
                </span>
                <span>Apagar Entrega</span>
              </div>
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

const NotasGrid = ({ notas, observacao, cancelar }: NotasGridProps) => {
  const dispatch: AppDispatch = useDispatch();

  const selectedNotas = useSelector(
    (state: RootState) => state.formOpenEntrega.data.selectedNotas
  );

  const entrega = useSelector(
    (state: RootState) => state.formOpenEntrega.data.entrega
  );

  const addSelectedNotaHandler = (nota: Nota) => {
    dispatch(addSelectedNota(nota));
  };

  const removeSelectedNotaHandler = (nota: Nota) => {
    dispatch(removeSelectedNota(nota));
  };

  return (
    <DataGrid
      slots={{
        pagination: () => (
          <CustomPagination
            cancelar={cancelar}
            notas={notas}
            selectedNotas={selectedNotas}
            observacao={observacao}
            dispatch={dispatch}
            entrega={entrega}
          />
        ),
      }}
      disableRowSelectionOnClick
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      rows={notas}
      getRowId={(nota) => {
        return nota.ctrc;
      }}
      columns={columns(addSelectedNotaHandler, removeSelectedNotaHandler)}
      onCellKeyDown={(_, events) => events.stopPropagation()}
    />
  );
};
export default NotasGrid;
