import NotaFiscal from '../../components/NotaFiscal';
import { formatDate } from '../../utils/formatter';
import { AppDispatch } from '../../state/store';
import { openNotaTab } from '../nota/state/notaAction';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { maiorQueDataAtual } from '../../utils/date';
import { Nota } from '../../data/types/Nota';
import { Cliente } from '../../data/types/Cliente';
import FormaAgendamento from './component/FormaAgendamento';
import { fetchNotaDataById } from '../nota/state/notaFetch';

function statusAgendamento(nota: Nota) {
  let agendamentoVencido = false;

  if (nota.dataAgendamento != null) {
    agendamentoVencido = maiorQueDataAtual(nota.dataAgendamento);
  }

  if (nota.dataAgendamento == null && nota.codigoOcorrencia != 35) {
    return (
      <span className='text-white bg-vencido rounded-full p-1 px-2'>
        Não agendado
      </span>
    );
  } else if (nota.dataAgendamento == null && nota.codigoOcorrencia == 35) {
    return (
      <span className='bg-agendado rounded-full p-1 px-2'>
        {formatDate(nota.dataUltimaOcorrencia)}
      </span>
    );
  } else if (nota.dataAgendamento != null && agendamentoVencido) {
    return (
      <span className='bg-ok rounded-full p-1 px-2 text-white'>
        {formatDate(nota.dataAgendamento)}
      </span>
    );
  } else if (nota.dataAgendamento != null && !agendamentoVencido) {
    return (
      <span className='bg-check rounded-full text-white p-1 px-2'>
        {formatDate(nota.dataAgendamento)}
      </span>
    );
  }
}

export const columns = (
  dispatch: AppDispatch,
  unidadesFiltro: string[],
  unidades: string[],
  clientes: Cliente[]
) => [
  {
    field: 'agendar',
    headerName: 'Agendar',
    flex: 2,
    renderCell: ({ row }: GridRenderCellParams) => {
      const cliente = clientes.find(
        (cliente) => cliente.cnpj === row.cnpjDestinatario
      );
      if (cliente === undefined) return null;
      return (
        <div className='whitespace-normal w-full'>
          <FormaAgendamento cliente={cliente} />
        </div>
      );
    },
  },
  {
    field: 'destinatario',
    headerName: 'Destinatário',
    flex: 2,
    renderCell: ({ row }: GridRenderCellParams) => {
      const cliente = clientes.find(
        (cliente) => cliente.cnpj === row.cnpjDestinatario
      );
      if (cliente === undefined) return null;
      return (
        <div className='flex flex-col gap-5'>
          <div>
            <strong>{row.destinatario}</strong>
          </div>
        </div>
      );
    },
  },
  { field: 'cidade', headerName: 'Cidade', flex: 1 },
  {
    field: 'notas',
    headerName: '',
    flex: 6,
    renderCell: ({ row }: GridRenderCellParams) => {
      const notas = row.notas.filter((nota: any) => {
        if (nota.rota === null) {
          return false;
        }
        if (unidadesFiltro.length === 0) {
          return unidades.includes(nota.rota?.unidade.nome as string);
        } else {
          return unidadesFiltro.includes(nota.rota?.unidade.nome as string);
        }
      });
      return (
        <div className='w-full' key={row.destinatario}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: '#E4E9F0',
                  }}
                >
                  <TableCell width='5%'>NF</TableCell>
                  <TableCell width='5%'>Ocorrencia</TableCell>
                  <TableCell width='5%'>Emissão</TableCell>
                  <TableCell width='20%'>Remetente</TableCell>
                  <TableCell width='5%'>Agendamento</TableCell>
                </TableRow>
                {notas.map((nota: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell width='5%'>
                      <div className='text-[#3B82F6] flex'>
                        <button
                          onClick={() =>
                            dispatch(fetchNotaDataById(nota.idNota))
                          }
                        >
                          <div className='flex gap-1 items-center'>
                            <FaMagnifyingGlass />
                            <NotaFiscal
                              numeroNotaFiscal={nota.numeroNotaFiscal}
                            />
                          </div>
                        </button>
                      </div>
                    </TableCell>
                    <TableCell width='5%'>{nota.codigoOcorrencia}</TableCell>
                    <TableCell width='5%'>
                      {formatDate(nota.dataAutorizacao)}
                    </TableCell>
                    <TableCell width='5%'>{nota.remetente}</TableCell>
                    <TableCell className='flex' width='5%'>
                      {statusAgendamento(nota)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableHead>
            </Table>
          </TableContainer>
        </div>
      );
    },
  },
];
