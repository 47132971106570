import { MenuItem, TextField } from '@mui/material';
import { Divider } from 'antd';
import { useDispatch } from 'react-redux';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../state/store';
import {
  createEntrega,
  fetchFormCreateEntregasData,
} from './state/formCreateEntregasFetch';
import Loading from '../../../../components/Loading';
import EntregasGrid from '../EntregasGrid';
import { closePanel } from '../../state/entregasAction';
import Fechar from '../../../../components/Fechar';

type FormCreateEntregasProps = {
  cancelar: Function;
};

const FormCreateEntregas = ({ cancelar }: FormCreateEntregasProps) => {
  const dispatch: AppDispatch = useDispatch();
  const [filtro, setFiltro] = React.useState('');
  const [observacao, setObservacao] = React.useState('');

  React.useEffect(() => {
    dispatch(fetchFormCreateEntregasData());
  }, []);

  const entregas = useSelector(
    (state: RootState) => state.entregas.data.entregas
  );

  const notasEntregas = entregas.map((entrega) => entrega.notas).flat();

  const notas = useSelector(
    (state: RootState) => state.formCreateEntregas.data.notas
  ).filter(
    (nota) =>
      !notasEntregas.some((notaEntrega) => notaEntrega.idNota === nota.idNota)
  );

  const destinatarios = useSelector(
    (state: RootState) => state.formCreateEntregas.data.destinatarios
  );

  if (notas.length === 0 || destinatarios.length === 0) {
    return <Loading />;
  }

  const handleRegistrar = () => {
    dispatch(createEntrega(observacao));
  };

  return (
    <div className='w-full h-full bg-white p-5 flex flex-col gap-5 overflow-hidden'>
      <Fechar
        titulo={'CRIAR PROTOCOLO DE ENTREGA'}
        onClick={() => cancelar()}
      />
      <Divider />
      <TextField
        multiline
        label='Observação'
        id='observacao'
        name='observacao'
        rows={3}
        fullWidth
        onChange={(e) => setObservacao(e.target.value)}
      />
      <TextField
        onChange={(e) => setFiltro(e.target.value)}
        label='Romaneio / NF / Destinatário'
        helperText={`Para selecionar a nota, o e-mail de protocolo do pagador do frete deve estar cadastrado. O e-mail pode ser cadastrado clicando em 'Cadastrar' na lista de notas ou acessando diretamente a tela de cadastro de clientes.`}
        id='busca'
        name='busca'
        fullWidth
        defaultValue={''}
      />
      <EntregasGrid
        filtro={filtro}
        cancelar={() => cancelar()}
        notas={notas.filter((nota) => {
          if (filtro !== '') {
            const notaNumeroNotaFiscal = notas.find((nota) =>
              nota.numeroNotaFiscal.includes(filtro)
            );
            return (
              (nota.codigoRomaneio &&
                (nota.codigoRomaneio.includes(filtro) ||
                  nota.codigoRomaneio
                    .replace(/\s+/g, '')
                    .includes(filtro.replace(/\s+/g, '')) ||
                  (notaNumeroNotaFiscal &&
                    notaNumeroNotaFiscal.codigoRomaneio ===
                      nota.codigoRomaneio))) ||
              nota.numeroNotaFiscal.includes(filtro) ||
              nota.destinatario.toUpperCase().includes(filtro.toUpperCase())
            );
          } else {
            return true;
          }
        })}
        registrar={handleRegistrar}
      />
    </div>
  );
};
export default FormCreateEntregas;
