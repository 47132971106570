import {
  Button,
  Drawer,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from '@mui/material';
import { AppDispatch, RootState } from '../../state/store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { closeNotaTab } from './state/notaAction';

import React, { useState } from 'react';

import { formatDataHora } from '../../utils/formatter';
import Box from '../../components/Box';
import { Acompanhamento } from '../../data/types/Acompanhamento';
import Ocorrencias from './components/Ocorrencias';
import DetalhesNota from './components/DetalhesNota';
import Header from './components/Header';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ value, index, children }: TabPanelProps) => {
  return (
    <div
      hidden={value !== index}
      className='h-full bg-[#f7f9fc]  overflow-hidden'
    >
      {value === index && (
        <React.Fragment>
          <div className='p-5 h-full w-full  overflow-hidden'>
            <div className='h-full w-full overflow-auto'>{children}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const Nota = () => {
  const dispatch: AppDispatch = useDispatch();

  const [value, setValue] = useState(0);
  const [descricao, setDescricao] = useState('');

  const isNotaOpen = useSelector((state: RootState) => state.nota.isOpen);

  const isMobile = useSelector(
    (state: RootState) => state.application.isMobile
  );

  const nota = useSelector((state: RootState) => state.nota.data.nota);

  const acompanhamentos = useSelector(
    (state: RootState) => state.nota.data.acompanhamentos
  ) as Acompanhamento[];

  // const handleCadastrarAcompanhamento = () => {
  //   if (nota !== undefined) {
  //     const acompanhamento = {
  //       descricao,
  //       idNota: nota.idNota,
  //     } as Acompanhamento;
  //     dispatch(createAcompanhamento(acompanhamento)).then(() => {
  //       setDescricao('');
  //     });
  //   }
  // };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '90%',
        },
      }}
      variant='temporary'
      open={isNotaOpen}
      onClose={() => dispatch(closeNotaTab())}
    >
      <div className='grid grid-rows-[112px_auto] h-full'>
        <div>
          <Header
            numeroNotaFiscal={
              nota !== undefined ? nota.numeroNotaFiscal : '...'
            }
          />
          <Box>
            <Tabs
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
            >
              <Tab label='Informações' />
              <Tab label='Acompanhamento' />
            </Tabs>
          </Box>
        </div>

        <div className='overflow-auto'>
          <TabPanel value={value} index={0}>
            <React.Suspense fallback={<div>Loading</div>}>
              <div className='grid grid-cols-12 grid-rows-6 h-full gap-5 text-md'>
                <Box className='col-span-6 row-span-6'>
                  <DetalhesNota />
                </Box>
                <Box className='col-span-6 row-span-6 overflow-y-scroll'>
                  <Ocorrencias />
                </Box>
              </div>
            </React.Suspense>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <React.Suspense fallback={<div>Loading</div>}>
              <div className='grid grid-cols-12 grid-rows-[210px_auto] h-full gap-5 text-md'>
                <Box className='col-span-12'>
                  <div className='p-5'>
                    <TextField
                      multiline
                      label='Descrição'
                      id='descricao'
                      name='descricao'
                      value={descricao}
                      rows={3}
                      fullWidth
                      onChange={(e) => setDescricao(e.target.value)}
                    />
                  </div>
                  <div className='float-right p-5'>
                    <Button
                      // onClick={() => handleCadastrarAcompanhamento()}
                      variant='contained'
                      color='primary'
                    >
                      Cadastrar
                    </Button>
                  </div>
                </Box>
                <Box className='col-span-12'>
                  <TableContainer sx={{ maxHeight: '100%' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell width={'200px'}>Data</TableCell>
                          <TableCell width={'300px'}>Usuário</TableCell>
                          <TableCell>Descrição</TableCell>
                        </TableRow>
                      </TableHead>
                      {acompanhamentos.map((acompanhamento, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {formatDataHora(acompanhamento.data)}
                          </TableCell>
                          <TableCell>{acompanhamento.email}</TableCell>
                          <TableCell>{acompanhamento.descricao}</TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </TableContainer>
                </Box>
              </div>
            </React.Suspense>
          </TabPanel>
        </div>
      </div>
    </Drawer>
  );
};
export default Nota;
